*,
*::after,
*::before {
  box-sizing: border-box;
}

:root {
  font-size: 16px;
  --color-text: #fff;
  --color-bg: #000;
  --color-bg-alt: #8ca9af;
  --color-link: rgba(0, 0, 0, 0.6);
  --color-link-hover: #000;
  --page-padding: 1.5rem;
  --color-title: #000;
}
.flip-x {
  transform: scaleX(-1);
}

.scene-moved-down {
  transform: translateY(50vh);
  transition: transform 2s ease-in-out;
}

.demo-2 {
  --color-bg: #111;
  --color-bg-alt: #7e8b92;
  --color-title: #fff;
}

.demo-3 {
  --color-bg: #191818;
  --color-bg-alt: #8b8078;
  --color-title: #ece6df;
}

body {
  margin: 0;
  color: var(--color-text);
  background-color: var(--color-bg);
  font-family: "Space Grotesk", -apple-system, BlinkMacSystemFont, Segoe UI,
    Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  height: 100vh;
  background: #191818;
  background-size: 150px, 100% 100vh;
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
  width: 3rem;
}

.logo {
  height: 100%;
  width: 100%;
}
.container {
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
  box-sizing: border-box;
}

.topArea {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 10rem;
  z-index: 1000;
}

.privacyPolicy {
  padding-top: 10rem;
}

.termsAndConditions {
  padding-top: 10rem;
}

.termsAndPrivacyWrapper {
  display: flex;
  gap: 8px;
  align-items: center;
}

.footer_nav_content .container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

/* Page Loader */
.js .loading::before,
.js .loading::after {
  content: "";
  position: fixed;
  z-index: 1000;
}

.js .loading::before {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--color-bg);
}

.js .loading::after {
  top: 50%;
  left: 50%;
  width: 60px;
  height: 60px;
  margin: -30px 0 0 -30px;
  border-radius: 50%;
  opacity: 0.4;
  background: var(--color-link-hover);
  animation: loaderAnim 0.7s linear infinite alternate forwards;
}

/* .container {
  position: relative;
  width: 100%;
  max-width: 850px;
  margin: 0px auto;
  padding: 0px 40px;
} */

@keyframes loaderAnim {
  to {
    opacity: 1;
    transform: scale3d(0.5, 0.5, 1);
  }
}

a {
  text-decoration: none;
  color: var(--color-link);
  outline: none;
  cursor: pointer;
}

a:hover {
  text-decoration: underline;
  color: var(--color-link-hover);
  outline: none;
}

/* Better focus styles from https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-visible */
a:focus {
  /* Provide a fallback style for browsers
	 that don't support :focus-visible */
  outline: none;
  background: lightgrey;
}

a:focus:not(:focus-visible) {
  /* Remove the focus indicator on mouse-focus for browsers
	 that do support :focus-visible */
  background: transparent;
}

a:focus-visible {
  /* Draw a very noticeable focus style for
	 keyboard-focus on browsers that do support
	 :focus-visible */
  outline: 2px solid red;
  background: transparent;
}

.unbutton {
  background: none;
  border: 0;
  padding: 0;
  margin: 0;
  font: inherit;
  cursor: pointer;
}

.unbutton:focus {
  outline: none;
}

.frame {
  padding: var(--page-padding);
  position: relative;
  text-transform: uppercase;
  font-size: 12px;
  display: grid;
  z-index: 1000;
  width: 100%;
  height: 100%;
  grid-row-gap: 1rem;
  grid-column-gap: 2rem;
  pointer-events: none;
  justify-items: start;
  grid-template-columns: auto auto;
  grid-template-areas: "title" "archive" "back" "github" "demos" "sponsor" "tags";
}

.frame #cdawrap {
  justify-self: start;
}

.frame a {
  pointer-events: auto;
}

.frame__title {
  grid-area: title;
  font-size: inherit;
  margin: 0;
}

.frame__back {
  grid-area: back;
  justify-self: start;
}

.frame__archive {
  grid-area: archive;
  justify-self: start;
}

.frame__tags {
  grid-area: tags;
}

.frame__github {
  grid-area: github;
}

.frame__demos {
  grid-area: demos;
  display: flex;
  gap: 1rem;
}
.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 15rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 10rem;
  /* background-color: var(--color-bg);
  background: linear-gradient(to top, var(--color-bg) 100%, transparent 0%);
  box-shadow: 0 -1rem 1rem -0.5rem rgba(0, 0, 0, 0.1); */
  /* background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 100%
  ); */
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.gloock-regular {
  font-family: "Space Grotesk", serif;
  font-weight: 400;
  font-style: normal;
}

.space-grotesk-light {
  font-weight: 300;
}

.space-grotesk-regular {
  font-weight: 400;
}

.space-grotesk-medium {
  font-weight: 500;
}

.space-grotesk-bold {
  font-weight: 700;
}

.debug {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100vw;
  height: 1px;
  border-radius: 5px;
  background-color: red;
  transform: translate(-50%, -50%);
}

.scene {
  perspective: 1000px;
  overflow: hidden;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
}

.card {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.card__img {
  width: 5em;
  border-radius: 5px;
  aspect-ratio: 43 / 93;
  background-position: center;
  background-size: cover;
}

.headings {
  text-align: center;
  position: relative;
  z-index: 10;
  color: var(--color-title);
  text-transform: uppercase;

  /* padding-top: 5vh; */
}

.headings__main {
  line-height: 1;
  font-size: clamp(2.5rem, 1.59rem + 3.883vw, 6.25rem);
  padding-top: 0;
  margin-top: 0;
  text-transform: lowercase;
}

main {
  position: relative;
}

.headings_subtitle {
  line-height: 0.5;
  font-size: clamp(0.8rem, 0.643rem + 0.881vw, 1.2rem);
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-transform: lowercase;
}

.headings_button_container {
  /* display: flex;
  justify-content: center;
  align-items: center; */
  display: block;
}

.headings_button {
  /* background-color: #fff; */
  /* color: #000; */
  color: var(--color-text);
  background-color: var(--color-bg);
  padding: 10px 20px;
  border-radius: 10px;
  text-transform: uppercase;
  font-size: 0.8rem;
  margin-top: 2vh;
  font-family: "Space Grotesk", -apple-system, BlinkMacSystemFont, Segoe UI,
    Helvetica, Arial, sans-serif;
  border: 1px solid var(--color-text);
  margin-top: 1.5rem;
  /* margin-bottom: 0.85rem; */
  display: inline-block;
}
.headings_button:hover {
  text-decoration: none; /* Remove underline on hover */
  color: inherit; /* Keep the same color on hover */
  outline: none;
}

.headings_link {
  /* text-decoration: underline; */
  font-size: 0.8rem;
  /* text-transform: upp; */
}
.headings_link a {
  text-decoration: underline;
  color: var(--color-text);
}

.headings_small {
  font-size: 0.65rem;
  /* margin-top: 2vh; */
}

@media screen and (min-width: 53em) {
  .frame {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    grid-template-columns: auto auto auto 1fr;
    grid-template-rows: auto auto;
    align-content: space-between;
  }
  .frame #cdawrap,
  .frame__demos {
    justify-self: end;
  }

  .card__img {
    width: 6em;
  }
}

.footer_nav a {
  color: var(--color-text);
  font-size: 0.8rem;
  text-decoration: none;
  text-transform: uppercase;
}

.footer_nav a:hover {
  color: var(--color-text);
  text-decoration: underline;
}

.footer_nav_content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer_nav {
  flex-direction: column;
  justify-content: flex-end;
  /* align-items: center; */
}

.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 23vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 10vh;
  /* background-color: var(--color-bg);
  background: linear-gradient(to top, var(--color-bg) 100%, transparent 0%);
  box-shadow: 0 -1rem 1rem -0.5rem rgba(0, 0, 0, 0.1); */
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 100%
  );
}

/* Markdown Content Styling */
.markdown-page {
  padding-top: 6rem;
  min-height: 100vh;
  background: var(--color-bg);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  padding-bottom: 10vh;
}

.markdown-content {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  line-height: 1.4;
  color: var(--color-text);
  font-size: 0.9rem;
  position: relative;
}

.markdown-content h1 {
  font-size: 1.6rem;
  margin-bottom: 1.5rem;
  font-weight: 700;
}

.markdown-content h2 {
  font-size: 1.2rem;
  margin: 1.5rem 0 0.75rem;
  font-weight: 600;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding-bottom: 0.3rem;
}

.markdown-content h3 {
  font-size: 1rem;
  margin: 1rem 0 0.75rem;
  font-weight: 600;
}

.markdown-content p {
  margin-bottom: 0.75rem;
  font-size: 0.9rem;
}

.markdown-content a {
  color: var(--color-text);
  text-decoration: underline;
  opacity: 0.9;
}

.markdown-content a:hover {
  opacity: 1;
  text-decoration: none;
}

.markdown-content ul,
.markdown-content ol {
  margin: 0.75rem 0;
  padding-left: 1.5rem;
}

.markdown-content li {
  margin-bottom: 0.4rem;
  font-size: 0.9rem;
}

.markdown-content em {
  font-style: italic;
}

.markdown-content strong {
  font-weight: 600;
}

.markdown-content blockquote {
  border-left: 4px solid rgba(255, 255, 255, 0.2);
  padding-left: 1rem;
  margin: 1rem 0;
  font-style: italic;
}

.markdown-content code {
  background: rgba(255, 255, 255, 0.1);
  padding: 0.2rem 0.4rem;
  border-radius: 3px;
  font-family: monospace;
}

/* Adjust the container for markdown pages */
.markdown-page {
  padding-top: 6rem;
  min-height: 100vh;
  background: var(--color-bg);
}

/* Add responsive adjustments */
@media screen and (max-width: 768px) {
  .markdown-content {
    padding: 1rem;
    font-size: 0.85rem;
  }

  .markdown-content h1 {
    font-size: 1.4rem;
  }

  .markdown-content h2 {
    font-size: 1.1rem;
  }

  .markdown-content h3 {
    font-size: 0.95rem;
  }
}

.not-found-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 6rem); /* Accounting for TopArea */
  color: var(--color-text);
  text-align: center;
}

.not-found-content h1 {
  font-size: 4rem;
  margin-bottom: 1rem;
  font-weight: 700;
}

.not-found-content p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
  opacity: 0.8;
}
